.bgpic {
  background-image: url("https://dearmacmedia.s3.amazonaws.com/assests/assets/macbg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.bgfriend {
  background-image: url("https://dearmacmedia.s3.amazonaws.com/assests/assets/Assets/Group+355.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
  z-index: 1;
}

.hide_bar::-webkit-scrollbar {
  display: none;
}
.slick-prev:before,
.slick-next:before {
  color: #ff7bac !important;
  background-color: white !important;
}

li.slick-active div {
  background-color: #ff7bac !important;
  box-shadow: 1px 1px 3px black;
}

.frame {
  height: 540px;
}
.pagination {
  display: flex;
  list-style: none;
  outline: none;
  padding: 0.5rem 0 !important;
}
.pagination > .active > a {
  background-color: #526484;
  border-color: #e5e9f2;
  color: #fff;
}
.pagination > li > a {
  border: none;
  padding: 9px 12px;
  outline: none;
  cursor: pointer;
  user-select: none;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  color: #213f7d;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 9px 12px;
  font-weight: 700;
}
.pagination > li > a,
.pagination > li > span {
  color: #526484;
  font-size: 13px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-radius: 4px 0 0 4px;
  background-color: #21407d64;
  padding: 9px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 0 4px 4px 0;
  background-color: #21407d64;
  padding: 9px;
}

@media screen and (max-width: 500px) {
  .frame {
    height: 400px;
  }
}
